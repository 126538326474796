import React from "react"
import { Link } from "gatsby"
import Nav from "./nav"
import Bio from "./bio"
import GlobalStyles from '../styles/GlobalStyles'

const Layout = ({ author, location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/blog`
  const isRootPath = location.pathname === rootPath

  const mainHeader = (
    <>
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
      <Bio />
    </>
  )

  const header = isRootPath ? mainHeader : null

  return (
    <>
      <GlobalStyles />
      <Nav />
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <div className="site">
          <header className="global-header">{header}</header>
          <main className="site-content">{children}</main>
          <footer>
            © {author} {new Date().getFullYear()}
          </footer>
        </div>
      </div>
    </>
  )
}

export default Layout
