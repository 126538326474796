import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components'
import Image from "gatsby-image"

const Nav = () => {
    const data = useStaticQuery(graphql`
      query {
        avatar: file(absolutePath: { regex: "/gravatar.png/" }) {
          childImageSharp {
            fixed(width: 50, height: 50, quality: 95) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `)

  const avatar = data?.avatar?.childImageSharp.fixed
  return (
    <nav className="custom-nav">
      <ul>
        {avatar && (
          <li className="fullwidth">
            <StyledLogoLink to="/">
              <Image
                fixed={avatar}
                className="logo-avatar"
                imgStyle={{
                  borderRadius: `50%`,
                }}
              />
            </StyledLogoLink>
          </li>
        )}
        <li>
          <StyledLink to="/archive">archive</StyledLink>
        </li>
        <li>
          <StyledLink to="/cheatsheets">cheatsheets</StyledLink>
        </li>
        <li>
          <StyledLink to="/contact">contact</StyledLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav

export const StyledLink = styled(Link)`
  padding: 0.5rem;
  text-decoration: none;
  border: solid transparent;
  border-width: 0 0 thick 10px;

  &:hover,
  &[aria-current="page"] {
    color: var(--purple);
    border: solid var(--purple);
    border-width: 0 0 thick 10px;
    border-color: transparent var(--purple) var(--purple) transparent;
    border-radius: 0 0 20px 0;
  }
`

const StyledLogoLink = styled(Link)`
  --borderSize: 8px;
  img {
    border: var(--borderSize) solid transparent;
    &:hover {
      width: var(--logoDimensions);
      border: var(--borderSize) solid var(--purple);
      border-radius: 500px;
      -webkit-border-radius: 500px;
      -moz-border-radius: 500px;
    }
  }
`

export const ExternalLink = styled.a`
  padding: 0.5rem;
  text-decoration: none;
  border: solid transparent;
  border-width: 0 0 thick 10px;

  &:hover {
    color:  var(--purple);
    border: solid  var(--purple);
    border-width: 0 0 thick 10px;
    border-color: transparent  var(--purple)  var(--purple) transparent;
    border-radius: 0 0 20px 0;
  }
`
