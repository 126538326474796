import { createGlobalStyle } from "styled-components"
import bg from "../../content/assets/splatter-background.png"

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --purple: #a553df;
    --logoDimensions: 125px;
  }

  body::after {
    content: "";
    background-image: url(${bg});
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--purple);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--purple) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--purple) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .logo-avatar {
    margin-right: var(--spacing-4);
    margin-bottom: var(--spacing-0);
    min-width: var(--logoDimensions);
    min-height: var(--logoDimensions);
    border-radius: 100%;
    border: 10px solid transparent;
  }

  nav.custom-nav {
      margin-bottom: 3rem;
      font-size: 2rem;
      max-width: 800px;
      margin: auto;

      @media (max-width: 1200px) {
        margin-bottom: 0;
      }

      .fullwidth {
        @media (max-width: 800px) {
          grid-column: 1 / 4;
          margin-bottom: 0;
        }
      }

    ul {
      margin: 0;
      padding: 0;
      text-align: center;
      list-style: none;

      display: grid;
      grid-template-columns: auto 1fr 1fr 1fr;
      grid-gap: 2rem;
      align-items: center;

      @media (max-width: 800px) {
        grid-gap: 0.5rem;
        grid-template-columns: repeat(3, auto)
      }

      @media (max-width: 500px) {
        grid-gap: 0.25rem;
      }
    }

    li {
      --rotate: -10deg;
      transform: rotate(var(--rotate));
      order: 1;

      &:nth-child(2) {
        --rotate: 1deg;
      }

      &:nth-child(3) {
        --rotate: -2.5deg;
      }

      &:nth-child(4) {
        --rotate: 2.5deg;
      }

      a {
        font-size: 2rem;
        text-decoration: none;
      }
    }
  }
`

export default GlobalStyles
